<template>
  <div class="primary-block">
    <h3 class="input-list-title" v-if="title">{{ title }}</h3>

    <ul class="input-list">
      <li
        class="input-list-item"
        v-for="(person, index) in persons"
        :key="index"
      >
        <div>
          <span class="primary-text">{{ person.fullName }}</span>
          <span class="secondary-text"
            >{{ person.iin }} • {{ person.riskRating}}</span
          >
        </div>
        <div @click="$emit('delete', index)" v-show="!(isReinsurance && (person.getPeopleId == holderId))">
          <icon icon-name="close-button-icon" />
        </div>
      </li>
      <input-field
        v-if="addNewPersonInput"
        :label="$t('enterIIN')"
        field-type="tel"
        :is-block="true"
        :value="personIin"
        name="iin"
        mask="############"
        @input="addPerson"
        :loading="loading"
      />
      <h5 class="danger-text">{{ localError }}</h5>
      <div
        @click="addNewPersonField()"
        :class="['input-add-id', { 'input-add-disabled': addBtnDisabled || loading }]"
      >
        <div class="icon-box">
          <icon icon-name="spinner" v-if="loading"/>
          <icon icon-name="plus-icon" v-else></icon>
        </div>
        <span class="text" :class="{'disabled':loading}">{{ buttonTitle }}</span>
      </div>
    </ul>
  </div>
</template>

<script>
import moment from "moment";
import InputField from "../BuyPolicy/InputField";
import SpinLoader from "../SpinLoader";

export default {
  name: "AddPersonInfo",
  components: {SpinLoader, InputField },

  props: {
    loading: {
      type: Boolean,
      default: true
    },
    holderId: null,
    isReinsurance: {
      type: Boolean,
      default: false
    },
    persons: {
      type: Array,
    },
    error: {
      type: String,
    },
    title: {
      type: String,
    },
    buttonTitle: {
      type: String,
    },
    addBtnDisabled: {
      type: Boolean,
    },
    addNewPersonInput: {
      type: Boolean,
    },
    personIin: {
      type: String,
    },
  },
  data(){
    return {
      localError: null
    }
  },
  watch: {
    error(newError) {
      this.localError = newError
    }
  },
  methods: {
    docDate(person) {
      const { clientDocuments } = person;

      if(!clientDocuments) { return moment().format('YYYY-MM-DD') }

      const personDocument = clientDocuments.find(doc => doc.docType === 'IC') || clientDocuments[0];

      return moment(personDocument.docIssueDate).format("YYYY-MM-DD");
    },
    addPerson(val) {
      if(val.length === 0) {
        this.localError = null
      }

      if (val.length === 12) {
        this.localError = null;

        const [verified, reason] = this.individualNumberChecker(val);

        if(verified) {
          this.$emit("input", val);
        } else {
          this.localError = reason
        }
      }
    },

    addNewPersonField() {
      const {addBtnDisabled} = this;

      if(addBtnDisabled) {
        return;
      }

      this.$emit("addNewField");
    },

    individualNumberChecker(iin) {
      var s = 0;
      var nn = iin.split("");
      for (var i = 0; i < 11; i++) {
        s = s + (i + 1) * nn[i];
      }
      var k = s % 11;
      if (k == 10) {
        s = 0;
        for (var i = 0; i < 11; i++) {
          var t = (i + 3) % 11;
          if (t == 0) {
            t = 11;
          }
          s = s + t * nn[i];
        }
        k = s % 11;
        if (k == 10)
          return [
            false,
            "ИИН не должен использоваться, ошибки при формировании контрольного разряда",
          ];
        return [
          k == iin.substr(11, 1),
          "Контрольный разряд ИИН неверный, должно быть " +
            k +
            ", а стоит " +
            iin.substr(11, 1),
        ];
      }
      return [
        k == iin.substr(11, 1),
        "Контрольный разряд ИИН неверный, должно быть " +
          k +
          ", а стоит " +
          iin.substr(11, 1),
      ];
    },
  },
};
</script>
