import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import ClientCheckModal from "../components/Modals/ClientCheckModal";

export default {
  data() {
    return {
      fetchingClientInfo: false,
      fetchingCarInfo: false,
      storePrefix: null,
      initialDate: null,
      phone_number: null,
      clientIin: null,
      clientError: null,
      personAddBtnDisabled: false,
      personAddNewInputShow: false,
      carNumber: null,
      carError: null,
      carAddBtnDisabled: false,
      carAddNewInputShow: false,
      clientChecking: false,
      agreement_id: null,
      agreement: null,
    }
  },
  watch: {
    cars: {
      async handler() {
        await this.recalculate();
      },
      deep: true
    },
    clients: {
      async handler() {
        if(!this.clientChecking) {
          await this.recalculate()
        }
      },
      deep: true
    },
    period: {
      async handler() {
        await this.recalculate();
      },
    },
    cascoReward: {
      async handler() { await this.recalculate() },
    },
    form: {
      deep: true,
      handler() {
        this.saveDraft();
      }
    }
  },
  computed: {
    ...mapGetters({
      product: 'INSURANCE_PRODUCT',
      authUser: 'authUser',
    }),
    beginDate: {
      get() {
        return this.$store.getters[`${this.storePrefix}/beginDate`];
      },
      set(value) {
        this.$store.commit(`${this.storePrefix}/SET_BEGIN_DATE`, value)
      }
    },
    period: {
      get() {
        return this.$store.getters[`${this.storePrefix}/period`]
      },
      set(value) {
        this.$store.commit(`${this.storePrefix}/SET_PERIOD`, value)
      }
    },
    email: {
      get() {
        return this.$store.getters[`${this.storePrefix}/email`]
      },
      set(value) {
        this.$store.commit(`${this.storePrefix}/SET_EMAIL`, value)
      }
    },
    friendBasket: {
      get() {
        return this.$store.getters[`${this.storePrefix}/friendBasket`]
      },
      set(value) {
        this.$store.commit(`${this.storePrefix}/SET_FRIEND_BASKET`, value)
      }
    },
    selectedProductId: {
      get() {
        return this.$store.getters[`${this.storePrefix}/selectedProductId`]
      },
      set(value) {
        this.changeSelectedProductId(value)
      }
    },
    selectedProductName: {
      get() {
        return this.$store.getters[`${this.storePrefix}/selectedProductName`]
      },
      set(value) {
        this.changeSelectedProductName(value)
      }
    },
    autoplus: {
      get() {
        return this.$store.getters[`${this.storePrefix}/autoplus`]
      },
      set(value) {
        this.$store.commit(`${this.storePrefix}/SET_AUTOPLUS`, value)
        this.$store.commit(`${this.storePrefix}/SET_BASKET_FRIEND`, value)
      }
    },
    customText() {
      let start = moment(this.beginDate).format("DD.MM.YYYY");
      let end = moment(this.endDate).format("DD.MM.YYYY");

      return `${start} - ${end}`;
    },
    personBtnIsDisabled() {
      if (this.personAddBtnDisabled) { return true }

      if (this.clients.length >= 5) { return true; }

      return this.cars.length > 1 && this.clients.length === 1;
    },
    carsBtnIsDisabled() {
      if (this.carAddBtnDisabled) { return true; }

      if (this.cars.length >= 5) { return true; }

      return this.clients.length > 1 && this.cars.length === 1;
    },
    loyalClientModal() {
      return (this.cars.length && this.isLoyalClient &&  !this.noBasketRegions && !this.cars.some(car => Number(car.tfType) !== 1))
    }
  },
  methods: {

    personAddNewField() {
      this.personAddBtnDisabled = true;
      this.personAddNewInputShow = true;
    },
    carAddNewField() {
      this.carAddBtnDisabled = true;
      this.carAddNewInputShow = true;
    },

    showClientCheckModal() {
      this.$modal.show(ClientCheckModal, {
        getterName: `${this.storePrefix}/clients`,
        handler: this.checkClientsDoc
      }, this.$modalConfig, {
        'before-open': this.beforeCheckModal,
        'before-close': this.beforeCloseCheckModal
      })
    },

    beforeCheckModal() {
      this.clientChecking = true
    },

    beforeCloseCheckModal() {
      this.clientChecking = false
    },

    showLoyalClientModal() {
      this.$modal.show('dialog', {
        title: this.$i18n.t('loyalClientModalTitle'),
        text: this.$i18n.t('loyalClientModalText'),
        buttons: [{
          title: 'OK',
          handler: () => {
            this.$modal.hide('dialog')
          }
        }]
      });
    },

    async validateIin(iin) {
      const {getPeopleByIin} = this

      // const loader = $loading.show({});
      this.fetchingClientInfo = true;
      this.clientError = null;

      try {
        await getPeopleByIin(iin);

        this.personAddBtnDisabled = false
        this.personAddNewInputShow = false

        this.clientIin = ""
        this.clientError = ""
      } catch (err) {
        const error = {
          message: err?.value || 'Ошибка при получении данных клиента',
          code: err?.statusCode || 0,
          title: `Ошибка при добавлении клиента ${iin}`,
        }

        this.onError(error)

        if (err.statusCode === 'DUPLICATE_ITEM') {
          this.clientError = this.$i18n.t('driverAlreadyAdded');
        } else if (err.value) {
          this.clientError = err.value
        } else {
          this.clientError = this.$i18n.t('serverError');
        }
      } finally {
        this.fetchingClientInfo = false;
        // loader.hide()
      }
    },

    async validateCarNumber(number) {
      const {getCarByLicensePlate} = this

      this.fetchingCarInfo = true;

      try {
        await getCarByLicensePlate(number);
        this.carAddBtnDisabled = false;
        this.carAddNewInputShow = false;
        this.carNumber = "";
        this.carError = ""
      } catch (err) {
        const error = {
          message: err?.value || 'Ошибка при получении данных ТС',
          code: err?.statusCode || 0,
          title: `Ошибка при добавлении ТС ${number}`,
        }

        this.onError(error)

        if (err.statusCode === 'DUPLICATE_ITEM') {
          this.carError = this.$i18n.t('carAlreadyAdded');
        } else if (err.value) {
          this.carError = err.value
        } else {
          this.carError = this.$i18n.t('serverError');
        }
      } finally {
        this.fetchingCarInfo = false

        this.$refs.carInfoInput.$el.scrollIntoView({behavior: "smooth", block: 'start'})
      }
    },
  }
}