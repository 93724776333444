<template>
  <div class="final-amount">
    <dl class="desc-list" v-if="amountLoaded">
      <div class="desc-list-item" v-show="insuredSum">
        <dt class="desc-term-final">{{$t('insuredSum')}}</dt>
        <dd class="desc-detail-final">{{ insuredSum }}</dd>
      </div>
      <div class="desc-list-item" v-if="isSub">
        <dt class="desc-term">
          <span v-if="discountValue"> {{$t('cost')}} за три месяца</span>
          <span v-else>Стоимость</span>
         </dt>
        <dd class="desc-detail">{{ amount }} ₸<template v-if="rateTotal">/м</template>  </dd>
      </div>
      <div class="desc-list-item" v-else>
        <dt class="desc-term">{{$t('cost')}} {{ productName }}</dt>
        <dd class="desc-detail">{{ amount }} ₸  </dd>
      </div>
      <template v-if="ogpo">
        <div v-for="item in ogpo" :key="item.id" class="desc-list-item">
          <dt class="desc-term">{{ item.name }}</dt>
          <dd class="desc-detail">{{ item.price }} ₸</dd>
        </div>
      </template>
      <div class="desc-list-item" v-if="bonusTitle && bonusValue">
        <dt class="desc-term">{{ bonusTitle }}</dt>
        <dd class="desc-detail">{{ bonusValue }} ₸</dd>
      </div>

      <div class="desc-list-item" v-if="isSub && discountValue">
        <dt class="desc-term">Скидка на первые три месяца </dt>
        <dd class="desc-detail">-{{ discountValue }} ₸</dd>
      </div>

       <div class="desc-list-item" v-if="isSub">
        <dt class="desc-term-final">
          <span v-if="discountValue"> Итого  на три месяца</span>
          <span v-else>Итого</span>
         </dt>
        <dd class="desc-detail-final">{{ fixSum }} ₸</dd>
      </div>
      <div class="desc-list-item" v-else>
        <dt class="desc-term-final">{{$t('total')}}</dt>
        <dd class="desc-detail-final">{{ fixSum }} ₸</dd>
      </div>
    </dl>
    <dl class="desc-list" v-else>
      <div class="desc-list-item">
        <dt class="desc-term">{{$t('cost')}} {{ productName }}</dt>
        <dd class="desc-detail skeleton w-50"></dd>
      </div>
      <template v-if="ogpo">
        <div v-for="item in ogpo" :key="item.id" class="desc-list-item">
          <dt class="desc-term">{{ item.name }}</dt>
          <dd class="desc-detail skeleton w-50"></dd>
        </div>
<!--        <div v-if="[1,2,3,4,11].contains($store.getters['ogpoForm/selectedProductId'])">-->
<!--        </div>-->
      </template>
      <div class="desc-list-item">
        <dt class="desc-term-final">{{$t('total')}}</dt>
        <dd class="desc-detail-final skeleton w-50"></dd>
      </div>
    </dl>
    <!-- <router-link :to="{ name: 'policy.buying.confirm' }" class="primary-btn"
      >Оплатить</router-link
    > -->
    <button class="primary-btn" @click="$emit('submit')" :disabled="!isValid">
      {{$t('payButton')}}
    </button>
  </div>
</template>

<script>
export default {
  name: "FinalAmount",
  props: {
    productName: {
      type: String,
      default:''
    },
    amount: {
      type: Number,
      default: 0,
    },
    fixSum: {
      type: Number,
      default: 0,
    },
    ogpo: {
      type: Array,
      default: null,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    amountLoaded: {
      type: Boolean,
      default: true,
    },
    insuredSum: {
      type: String,
      default: null,
    },
    bonusTitle: {
      type: String,
      default: null
    },
    bonusValue: {
      type: Number,
      default: null
    },
    rateTotal: {
      type: Boolean,
      default: false
    },
    totalTitle: {
      type: String,
      default: null
    },
    discountTitle: {
      type: String,
      default: null
    },
    discountValue: {
      type: Number,
      default: null
    },
    isSub:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ogpoFixSumParameters: {
        saleOnline: "Скидка 10%",
        bonusSum: "Стоимость бонуса",
        costProduct: 'Стоимость "Автоплюс online"',
        saleCostProduct: "Скидка на продукт",
        sale: "Скидка",
        kasko: "КАСКО ЛАЙТ",
      },
    };
  },
  computed: {
    isValid() {
      return !this.invalid && this.amountLoaded && this.amount && this.fixSum > 0;
    }
  }
};
</script>

<style scoped>
</style>
